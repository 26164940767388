header {
    height: 110vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.signature {
    width: 8rem;
    position: absolute;
    overflow:visible;
    left: 35%;
    top: 80%;
    transform: translateX(-50%);

}

/* =============== CTA ============== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* =============== HEADER SOCIALS ============== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* =============== ME ============== */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
}

.me img {
    height: 60vh;
    margin-left: 2vh;
}

/* =============== SCROLL DOWN ============== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
    .me {
        margin-top: 8vh;
    }

        .signature {
            left: 25%
        }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
    header {
        height: 102vh;
        padding-top: 6rem;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }

    .me {
        width: 94%;
        max-width: 20rem;
        margin-top: 2.5rem;
        justify-content: center;
    }

    .signature {
        left: 15%
    }

    .cta {
        margin-top: 1.7rem;
        gap: 1rem;
    }
}